import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueSession from 'vue-session';
import Notifications from 'vue-notification';
import App from './App.vue';
import {router} from './router';
import store from './store';
import VueAuthHref from 'vue-auth-href';
import VueMeta from 'vue-meta';
import VueGtm from '@gtm-support/vue2-gtm';
import './store/mixins';
import './services/resource/index';
import 'swiper/css/swiper.css';
import 'bootstrap';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueSession, { persist: true });
Vue.use(VueAuthHref, {
  textMode: 'html',
  downloadingHtml: '<i class=\'bx bx-loader-circle\' style="font-size: 25px; color: #DBDBDB;"></i>'
});

Vue.use(VueGtm, {
  id: "GTM-5SNN93H", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.use(Notifications);

Vue.config.productionTip = false;

Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
