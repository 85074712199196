<template>
  <div id="app">
    <router-view/>
    <notifications group="app" position="top right"/>
  </div>
</template>
<script>
export default {
  name: 'FrontendApp',
  metaInfo(){
    return {
      title: 'Home',
      titleTemplate: '%s | Do Genius On',
      meta: [
        { name: 'facebook-domain-verification', content: '7j5m3e2dwshbdhfst3sgkmh405wvkp' }
      ]
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" src="./assets/stylesheets/application.scss"></style>
