import Vue from 'vue';
import VueResource from 'vue-resource';
import {router} from '../../router/index';
import consts from '../constants';
Vue.use(VueResource);

// Local
// Vue.http.options.root = 'http://127.0.0.1:8000/api/';
// QA
Vue.http.options.root = consts.URL_API;
Vue.http.headers.common['Access-Control-Allow-Origin'] = true;
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.http.headers.common['Accept-Language'] = 'es';
Vue.http.headers.common['DYNAMIC-LANGUAGE'] = 'es';
Vue.http.headers.common['Content-Type'] = 'application/json';
Vue.http.headers.common.Accept = 'application/json';
Vue.http.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS';
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Accept-Language';
Vue.http.headers['Access-Control-Max-Age'] = '1728000';
Vue.http.interceptors.push((request, next) => {
  next((response) => {
    if (response.status == 404 || response.status == 401) {
      router.app.$session.destroy();
      if (router.app.$route.name != 'login') {
        router.app.$router.push({name: 'login'});
      }
    }else if(response.status == 419) {
      router.app.$router.push({ name: 'page.419' });
    }
  });
});
