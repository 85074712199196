import Vue from 'vue';

Vue.mixin({
  filters: {
    shortTitle(val){
      return val.length >= 90 ? val.substring(0,90)+'...' : val;
    }
  },
  methods: {
    checkPermission(obj) {
      return (this.$session.get('userInfo').permissions).some((permission) => permission.name === obj);
    },
    checkLevelPassword(value){
      var levelPassword = {
        title: null,
        description: null,
        level: 'level_1',
        success: false
      };

      if (value != '') {
        if (!new RegExp("^(?=.{8,}).*$", "g").test(value)) {
          levelPassword.description = 'Your password must be at least 8 characters long.';
        }else if(!new RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$", "g").test(value)){
          levelPassword.description = 'Your password must have a combination of uppercase and lowercase letters.';
        }else if(!new RegExp("^(?=.*\\d).*$", "g").test(value)){
            levelPassword.description = 'Your password must have at least one digit.';
        }else if(!new RegExp("^(?=.*\\W).*$", "g").test(value)) {
            levelPassword.description = 'Your password must be at least one special character.';
        }else{
            levelPassword.description = 'Your password is strong enough.';
        }

        if(new RegExp("^(?=.{8,})((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)|((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*\\W)).*$", "g").test(value)){
            levelPassword.success = true;
        }else{
            levelPassword.success = false;
        }

        if(new RegExp("^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$", "g").test(value)){
            levelPassword.title = 'Strong';
            levelPassword.level = 'level_4';
            levelPassword.success = true;

        }else if(new RegExp("^(?=.{8,})((?=.*[a-z])(?=.*[A-Z])(?=.*\\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)|((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*\\W)).*$", "g").test(value)){
            levelPassword.title = 'Medium';
            levelPassword.level = 'level_3';
            levelPassword.success = true;

        }else{
            levelPassword.title = 'Weak';
            levelPassword.level = 'level_3';

            if (new RegExp("^(?=.{8,}).*$", "g").test(value)) {
                levelPassword.level = 'level_2';
                levelPassword.success = false;
            }else{
                levelPassword.level = 'level_1';
                levelPassword.success = false;
            }
        }
      }
      return levelPassword;
    },
    getModules(allPermissions){
      const modules = [];
      allPermissions.forEach(module => {
        if (!modules.find(md => md.name == module.split('-')[1].trim())) {
          modules.push({
            name: module.split('-')[1].trim(),
            permissions: this.getModulePermissions(module.split('-')[1].trim(), allPermissions)
          });
        }
      });
      return modules;
    },
    getModulePermissions(module, permissions){
      const listPermissions = [];
      permissions.forEach((permission) => {
        if (module == permission.split('-')[1].trim()) {
          listPermissions.push({
            value: permission,
            text: permission.split('-')[0].trim()
          });
        }
      });
      return listPermissions;
    },

    addLogo( strLinkArticle ){
      let strBaseUrl = strLinkArticle.replace(/http[s]?:\/\//, "");
      strBaseUrl = strBaseUrl.replace(/[\/#|\?|\/#]+.*$/, "");
      let strUrlLogo = "https://logo.clearbit.com/" + strBaseUrl;
      return strUrlLogo;
    },
    addAvatar(strLinkArticle){
      let strBaseUrl = strLinkArticle.replace(/http[s]?:\/\//, "");
      strBaseUrl = strBaseUrl.replace(/[\/#|\?|\/#]+.*$/, "");

      let arr = strBaseUrl.split('.');
      let avatar = null;

      if (arr.length > 1) {
        if (arr[0] == 'www') {
          if(arr.length == 3) {
            avatar = arr[1].substr(0,1).toUpperCase();
          }
          if(arr.length == 4) {
            avatar = arr[1].substr(0,1).toUpperCase() + arr[2].substr(0,1).toUpperCase();
          }
          if(arr.length >= 5) {
            avatar = arr[1].substr(0,1).toUpperCase() + arr[2].substr(0,1).toUpperCase() + arr[3].substr(0,1).toUpperCase();
          }
        } else {
          if(arr.length == 2) {
            avatar = arr[0].substr(0,1).toUpperCase();
          }
          if(arr.length == 3) {
            avatar = arr[0].substr(0,1).toUpperCase() + arr[1].substr(0,1).toUpperCase();
          }
          if(arr.length >= 4) {
            avatar = arr[0].substr(0,1).toUpperCase() + arr[1].substr(0,1).toUpperCase() + arr[2].substr(0,1).toUpperCase();
          }
        }
      }

      return avatar;
    },
  },
});
