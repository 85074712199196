import Vue from 'vue';
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import requireAuth from './permissionsRoute';
import consts from '../services/constants'

Vue.use(VueRouter);
Vue.use(VueResource);
const axios = require('axios');

const routes = [
  {
    name: 'home',
    path: '/',
    component: function() {
      return import('../views/Home/Index.vue')
    },
    meta: {
      sitemap: {
        lastmod: new Date().toISOString().slice(0,10),
        priority: 1.0,
        changefreq: 'daily',
      }
    }
  },
  {
    path: '/news-room/:slug',
    name: 'news-room.slug',
    component: function() {
      return import('../views/Home/NewsRoomDetail.vue')
    },
    meta: {
      sitemap: {
        slugs: async () => {
          const { data } = await axios.get(`${consts.URL_API}posts/dgo`);
          return data.data.map((slug) => slug.slug)
        }
      }
    }
  },
  {
    name: 'login',
    path: '/login',
    component() {
      return import('../views/Auth/Login.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    name: 'password.reset',
    path: '/password/reset',
    component() {
      return import('../views/Auth/Password/ForgotPassword.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    name: 'password.reset.token',
    path: '/password/reset/:token',
    component() {
      return import('../views/Auth/Password/ResetPassword.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    name: 'logout',
    path: '/logout',
    component() {
      return import('../views/Auth/Logout.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    name: 'page.419',
    path: '/419',
    component() {
      return import('../views/Errors/419.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    name: 'page.404',
    path: '*',
    component() {
      return import('../views/Errors/404.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    }
  },
  {
    path: '/dashboard',
    component() {
      return import('../views/Dashboard/Index.vue');
    },
    meta: {
      sitemap: {
        ignoreRoute: true
      }
    },
    children: [
      {
        name: 'dashboard',
        path: '/',
        meta: {
          name: 'Dashboard',
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/Dashboard.vue');
        },
      },
      {
        name: 'account_info.index',
        path: '/dashboard/account',
        meta: {
          name: 'Account Information',
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/Account/Index.vue');
        },
      },
      {
        name: 'posts.index',
        path: '/dashboard/posts',
        meta: {
          name: 'Posts',
          permission: 'List Posts - Posts',
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/Post/Index.vue');
        },
        beforeEnter: requireAuth
      },
      {
        name: 'posts.edit',
        path: '/dashboard/posts/:post/edit',
        meta: {
          name: 'Edit Post',
          permission: 'Edit Post - Posts',
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/Post/Edit.vue');
        },
        beforeEnter: requireAuth
      },
      {
        name: 'posts.create',
        path: '/dashboard/posts/add',
        meta: {
          name: 'Add New Post',
          permission: 'Create Post - Posts',
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/Post/Add.vue');
        },
        beforeEnter: requireAuth
      },
      {
        name: 'users.index',
        path: '/dashboard/users',
        meta: {
          name: 'Users',
          permission: ['List Users - Users','List Content Creator - Users'],
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/User/Index.vue');
        },
        beforeEnter: requireAuth
      },
      {
        name: 'users.create',
        path: '/dashboard/users/add',
        meta: {
          name: 'Add New User',
          permission: ['Create User - Users', 'Create Content Creator - Users'],
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/User/Add.vue');
        },
        beforeEnter: requireAuth
      },
      {
        name: 'users.edit',
        path: '/Dashboard/users/:user/edit',
        meta: {
          name: 'Edit User',
          permission: ['Edit User - Users', 'Edit Content Creator - Users'],
          sitemap: {
            ignoreRoute: true
          }
        },
        component() {
          return import('../views/Dashboard/User/Edit.vue');
        },
        beforeEnter: requireAuth
      },
      {
        path: '/dashboard/permissions',
        name: 'permissions.index',
        meta: {
          name: 'Roles and Permissions',
          permission: 'List Roles and Permissions - Roles and Permissions',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Permissions/Index.vue')
        },
        beforeEnter: requireAuth
      },
      {
        path: '/dashboard/permissions/add',
        name: 'permissions.create',
        meta: {
          name: 'Add New role',
          permission: 'Create Role - Roles and Permissions',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Permissions/Add.vue')
        },
        beforeEnter: requireAuth
      },
      {
        path: '/dashboard/permissions/:id/edit',
        name: 'permissions.edit',
        meta: {
          name: 'Edit role',
          permission: 'Edit Role - Roles and Permissions',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Permissions/Edit.vue')
        },
        beforeEnter: requireAuth
      },
      // Marketing
      {
        path: '/dashboard/marketing/forms',
        name: 'forms.index',
        meta: {
          name: 'Forms',
          permission: 'List Forms - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/Index.vue')
        },
        beforeEnter: requireAuth
      },
      {
        path: '/dashboard/marketing/forms/add',
        name: 'forms.create',
        meta: {
          name: 'Add new form',
          permission: 'Create Form - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/Add.vue')
        },
        beforeEnter: requireAuth
      },

      {
        path: '/dashboard/marketing/forms/:form/edit',
        name: 'forms.edit',
        meta: {
          name: 'Edit form',
          permission: 'Edit Form - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/Edit.vue')
        },
        beforeEnter: requireAuth
      },

      {
        path: '/dashboard/marketing/forms/:form/users_suscribed',
        name: 'forms.suscribed',
        meta: {
          name: 'Users subscribed to form',
          // permission: 'List Users Subscribed - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/UsersSuscribed.vue')
        },
        // beforeEnter: requireAuth
      },

      {
        path: '/dashboard/marketing/forms/:form/users_suscribed/:email',
        name: 'forms.submitted_form',
        meta: {
          name: 'Information sent',
          // permission: 'List Information Sent - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/InformationSent.vue')
        },
        // beforeEnter: requireAuth
      },
      {
        path: '/dashboard/marketing/forms/:form_id/notifications',
        name: 'form.notifications',
        meta: {
          name: 'Form notifications',
          // permission: 'List Form Notifications - Marketing',
          sitemap: {
            ignoreRoute: true
          }
        },
        component: function(){
          return import('../views/Dashboard/Marketing/Forms/Notifications/Add.vue')
        },
        // beforeEnter: requireAuth
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 80 }
      };
    }
    return { x: 0, y: 0 };
  },
});

export {router, routes};
