import {router} from './index';

const requireAuth = (to, from, next) => {
  if(Array.isArray(to.meta.permission)) {
    router.app.$session.get('userInfo').permissions.some((permission) => permission.name === to.meta.permission.find((meta) => meta === permission.name)) ? next() : router.app.$router.push({ name: 'dashboard' });
  } else {
    router.app.$session.get('userInfo').permissions.some((permission) => permission.name === to.meta.permission) ? next() : router.app.$router.push({ name: 'dashboard' });
  }
};

export default requireAuth;
